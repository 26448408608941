import styled from 'styled-components';

export const Container = styled.div`
    max-width: 1140px;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    @media  (max-width: 768px) {
        width: unset,;
    }
`;
export const Navbar = styled.nav`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    position: relative;
    padding: .5rem 1rem;
    @media (max-width: 768px) {
        padding: .5rem 0;
        display: block;
    }
`;
export const NavbarLogo = styled.span`
    display: inline-block;
    padding-top: .33594rem;
    padding-bottom: .33594rem;
    margin-right: 1rem;
    font-size: 1.09375rem;
    line-height: inherit;
    white-space: nowrap;
`;
export const LogoSvg = styled.svg`
    width: 157px;
    height: 40px;
    @media screen and (max-width: 768px) {
        width: 170px;
        height: auto;
    }
`;
export const Form = styled.div`
    max-width: 423px;
    margin: 100px auto 60px;
    text-align: center;
`;
export const Heading = styled.h4`
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 3rem;
    font-size: 1.5625rem;
`;
export const Description = styled.div`
    font-size: 12px;
    color: #9898b5;
    font-weight: 600;
    margin-bottom: 3rem
`;
export const InputContainer = styled.div`
    margin-bottom: 1.5rem;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
`;
export const InputTextArea = styled.textarea`
    width: 100%;
    padding: .844rem 1.4375rem;
    font-size: .875rem;
    font-family: sans-serif;
    line-height: 1.5;
    border-radius: .625rem;
    border-right: 0;
    background-color: #fff;
    border-color: transparent;
`;
export const InputText = styled.input`
    width: 100%;
    padding: .844rem 1.4375rem;
    font-size: .875rem;
    font-family: sans-serif;
    line-height: 1.5;
    border-radius: .625rem;
    border-right: 0;
    background-color: #fff;
    border-color: transparent;
`;

export const Select = styled.select`
    width: 100%;
    padding: .844rem 1.4375rem;
    font-size: .875rem;
    font-family: sans-serif;
    line-height: 1.5;
    border-radius: .625rem;
    border-right: 0;
    border-color: transparent;

    appearance: none;
    background: #fff url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E') no-repeat;
    background-position: right .7em top 50%, 0 0;
    background-size: .65em auto, 100%;
`;

export const Button = styled.button`
    cursor: pointer;
    background-color: #f2691b;
    border-color: #f2691b;
    border: 1px solid;
    color: #fff;
    padding: .90625rem 1.75rem;
    font-size: .875rem;
    line-height: 1.6;
    border-radius: .625rem;
    display: inline-flex!important;
    align-items: center;
    justify-content: center;
    position: relative;
`;
export const DownloadSpan = styled.span`
    text-transform: uppercase;
    font-weight: 600;
`;
export const Alert = styled.div`
    margin-top: 44px;
    font-size: 12px;
    border: 0;
    color: #fef7f7;
    background-color: #e23a3a;
    position: relative;
    padding: .3rem 1.1764rem;
    margin-bottom: 1rem;
    border-radius: .25rem;
    display: block;
`;
export const AlertMessage = styled.p`
    margin: 0;
`;

