import React from 'react';
import * as bip32 from 'bip32';
import { Account } from '@solana/web3.js';
import nacl from 'tweetnacl';
import pbkdf2 from "pbkdf2";
import aesjs from "aes-js";
import cryptoRandomString from "crypto-random-string";
import {
    Alert,
    AlertMessage,
    Button,
    Container,
    Description,
    DownloadSpan,
    Form,
    Heading,
    InputContainer,
    InputText,
    InputTextArea,
    Navbar,
    NavbarLogo,
    Select,
} from '../../styles/styles';
import { Logo } from './subcomponents/logo';

const derivationPaths = [
    {
        name: `m/501'/0’/0/0 (sollet)`,
        value: `m/501'/0'/0/0`,
    },
    {
        name: `m/44'/501' (ledger #1)`,
        value: `m/44'/501'`,
    },
    {
        name: `m/44'/501'/0'/0' (ledger #2)`,
        value: `m/44'/501'/0'/0'`,
    },
];

export class ConvertMnemonic extends React.Component {
    state = {
        mnemonic: '',
        password: '',
        repeatPassword: '',
        message: 'Error',
        errorVisible: false,
        successVisible: false,
        derivationPath: derivationPaths[0].value,
        customDerivationPath: '',
    }

    handleClick = async () => {
        this.setState({ errorVisible: false })

        const { password } = this.state;

        let valid = await this.validate();

        if (valid) {
            let seed = await this.generateMnemonicAndSeed();
            let account
            try {
                account = this.getAccountFromSeed(seed);
            } catch {
                this.setState({
                    message: `Wrong derivation path.`,
                    errorVisible: true
                });
                return;
            }
            const keystoreFile = this.encrypt(account, password);

            let filename = `solflare-keystore-${account.publicKey.toString()}.json`;
            let contentType = "application/json;charset=utf-8;";

            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                var blob = new Blob([decodeURIComponent(encodeURI(JSON.stringify(keystoreFile)))], { type: contentType });
                await navigator.msSaveOrOpenBlob(blob, filename);
            } else {
                var a = document.createElement('a');
                a.download = filename;
                a.href = 'data:' + contentType + ',' + encodeURIComponent(JSON.stringify(keystoreFile));
                a.target = '_blank';
                document.body.appendChild(a);
                await a.click();
                document.body.removeChild(a);
            }
            this.setState({
                successVisible: true,
                mnemonic: '',
                password: '',
                repeatPassword: '',
            })
        }
    }

    encrypt = (account, password) => {
        const options = {
            c: 8192,
            dklen: 32,
            prf: "sha512",
            salt: cryptoRandomString({ length: 64 }),
        }

        const key = pbkdf2.pbkdf2Sync(password, options.salt, options.c, options.dklen, options.prf)
        const counter = Math.floor(100000 + Math.random() * 900000);
        const aesCtr = new aesjs.ModeOfOperation.ctr(key, new aesjs.Counter(counter));
        const encryptedBytes = aesCtr.encrypt(account.secretKey);
        const encryptedHex = aesjs.utils.hex.fromBytes(encryptedBytes);

        const keystore = {
            publicKey: account.publicKey.toString(),
            Crypto: {
                cipher: "aes-128-ctr",
                ciphertext: encryptedHex,
                cipherparams: {
                    counter: counter
                },
                kdf: "pbkdf2",
                kdfparams: options
            },
        }
        return keystore;
    }

    getAccountFromSeed = (seed) => {
        const { derivationPath, customDerivationPath } = this.state;

        const path = derivationPath === 'custom'
          ? customDerivationPath
          : derivationPath;

        console.log(path);

        const derivedSeed = bip32
          .fromSeed(seed)
          .derivePath(path).privateKey;

        return new Account(nacl.sign.keyPair.fromSeed(derivedSeed).secretKey);
    }

    generateMnemonicAndSeed = async () => {
        const { mnemonic } = this.state;
        const bip39 = await import('bip39');
        const seed = await bip39.mnemonicToSeed(mnemonic);
        return Buffer.from(seed);
    }

    validate = () => {
        const {
            mnemonic,
            password,
            repeatPassword,
            derivationPath,
            customDerivationPath,
        } = this.state;
        let mnemonicArray = mnemonic.split(' ');

        if (mnemonic === '') {
            this.setState({
                message: 'Phrases are required',
                errorVisible: true
            });
            return false;
        } else if (password === '') {
            this.setState({
                message: 'Password is required',
                errorVisible: true
            });
            return false;
        } else if (repeatPassword === '') {
            this.setState({
                message: 'Repeat password is required',
                errorVisible: true
            });
            return false;
        } else if (mnemonicArray.length !== 12) {
            this.setState({
                message: 'Wrong phrases',
                errorVisible: true
            });
            return false;
        } else if (repeatPassword !== password) {
            this.setState({
                message: "Passwords don't match",
                errorVisible: true
            });
            return false;
        } else if (derivationPath === 'custom' && customDerivationPath.length === 0) {
            this.setState({
                message: "Custom derivation path is required.",
                errorVisible: true
            });
            return false;
        } else
            return true;
    }

    handleChange = (e) => {
        const { name, value } = e.target
        this.setState({
            [name]: value
        })
    }

    render() {
        const {
            mnemonic,
            password,
            repeatPassword,
            message,
            errorVisible,
            successVisible,
            derivationPath,
            customDerivationPath,
        } = this.state;
        return (
            <Container className="container">
                <Navbar className="navbar" >
                    <Container className="container" style={{ textAlign: 'left' }}>
                        <NavbarLogo className="navbar-logo">
                            <Logo />
                        </NavbarLogo>
                    </Container>
                </Navbar>
                <Form className="form">
                    <Heading className="heading">Convert wallet</Heading>
                    <div>
                        <Description className="description">
                            To convert a wallet, please pick a password first. Your password
                            should contain at least 8 characters
                        </Description>
                        <InputContainer className="input-container">
                            <Select name="derivationPath" value={derivationPath} onChange={this.handleChange}>
                                {derivationPaths.map(item => (
                                  <option
                                    key={item.value}
                                    value={item.value}
                                  >
                                      {item.name}
                                  </option>
                                ))}
                                <option value="custom">Custom path</option>
                            </Select>
                        </InputContainer>
                        {derivationPath === 'custom' && (
                          <InputContainer className="input-container">
                              <InputText
                                name="customDerivationPath"
                                placeholder="m/501'/0’/0/0"
                                type="text"
                                className="input"
                                value={customDerivationPath}
                                onChange={(e) => this.handleChange(e)}
                                id="path"
                              />
                          </InputContainer>
                        )}
                        <InputContainer className="input-container">
                            <InputTextArea
                                className="input"
                                rows="4"
                                name="mnemonic"
                                id="mnemonic-download"
                                placeholder="Write your 12 phrases separated by space"
                                value={mnemonic}
                                onChange={(e) => this.handleChange(e)}
                            />
                        </InputContainer>
                        <InputContainer className="input-container">
                            <InputText
                                name="password"
                                placeholder="Password"
                                type="password"
                                className="input"
                                value={password}
                                onChange={(e) => this.handleChange(e)}
                                id="password"
                            />
                        </InputContainer>
                        <InputContainer className="input-container">
                            <InputText
                                name="repeatPassword"
                                placeholder="Repeat Password"
                                type="password"
                                className="input"
                                value={repeatPassword}
                                onChange={(e) => this.handleChange(e)}
                                id="password-repeat"
                            />
                        </InputContainer>
                        {errorVisible &&
                            <Alert className="alert" id="alert">
                                <span>
                                    <AlertMessage
                                        className="alert-message"
                                        id="alert-message"
                                    >
                                        {message}
                                    </AlertMessage>
                                </span>
                            </Alert>
                        }
                        {successVisible &&
                            <Alert
                                className="alert"
                                id="alert"
                                style={{ backgroundColor: '#4BCA81' }}
                            >
                                <span>
                                    <AlertMessage
                                        className="alert-message"
                                        id="alert-message"
                                    >
                                        Successful conversion
                                    </AlertMessage>
                                </span>
                            </Alert>
                        }
                        <div>
                            <Button
                                onClick={this.handleClick}
                                type="button"
                                className="button"
                            >
                                <DownloadSpan
                                    className="download-span"
                                >
                                    Download
                            </DownloadSpan>
                            </Button>
                        </div>
                    </div>
                </Form>
            </Container>
        )
    }
}