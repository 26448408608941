import './App.css';
import { ConvertMnemonic } from '../src/modules/convertMnemonic/convertMnemonic'

function App() {
  return (
    <div className="App">
      <ConvertMnemonic />
    </div>
  );
}

export default App;
